.project-container {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.project-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px; /* Adjust this value to control the width of each project */
  width: 100%;
  transition: transform 0.3s ease-in-out;
  margin: 0 15px; /* Add margin for better spacing between projects */
}

.project-content:hover {
  transform: translateY(-5px);
}

.project-content h2 {
  color: #333;
  font-size: 22px;
  margin-bottom: 10px;
}

.project-content h3 {
  color: #791b91;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.project-content h3:hover {
  font-size: 25px;
  color:#470e4d
}

.project-content p {
  font-size: 16px;
  margin-bottom: 15px;
  color: #555;
}

.project-media {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-media img {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-media iframe {
  width: 100%;
  max-width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
