@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:200');


.back {
  background-image: url('https://static.pexels.com/photos/414171/pexels-photo-414171.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  
  position: relative;

  background-size:cover;
        -webkit-animation: slidein 100s;
        animation: slidein 100s;

        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;

        -webkit-animation-direction: alternate;
        animation-direction: alternate;     
}

/* @-webkit-keyframes slidein {
from {background-position: top; background-size:7000px; }
to {background-position: -100px 0px;background-size:4750px;}
} */

@keyframes slidein {
from {background-position: top;background-size:7000px; }
to {background-position: -100px 0px;background-size:3750px;}

}

.center
{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(28, 28, 87, 0.3);
  border-radius: 3px;
}
.center h1{
  position: relative;
  top: 0px;
  font-size: 20px;
  text-align:center;
  color:white;
  font-family: 'Source Code Pro', monospace;
  text-transform:uppercase;
}

.lower h1 {
  position: relative;
  top: 500px;
  font-size: 15px;
  text-align:center;
  color:white;
  font-family: 'Source Code Pro', monospace;
}


.projects-container {
  padding: 20px; /* Added padding to the projects container */
}

.projects-title {
  font-size: 24px;
  text-align: center;
  color: #0e0b0b; /* Adjusted text color for visibility */
  margin-bottom: 20px;
}

