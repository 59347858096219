/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap'); */

/* base styles */

* {
    margin: 0;
    /*-ms-overflow-style: none;  /* IE and Edge */
    /* scrollbar-width: none;  /* Firefox */
}
*::-webkit-scrollbar {
    display: none;
}



